<template>
  <div class="detailpannel">
    <div>
      
      <div v-if="status=='node-selected'" class="pannel" id="node_detailpannel">
        <div class="pannel-title" style="color:skyblue;font-size: 14px;text-align: center;background-color: #fff;">{{$t('projectlist.btn.ParameterSetting')}}</div>
        <div class="block-container" style="font-size: 15px;">
         
          <el-row style="padding-top: 5px;">{{$t('projectlist.btn.name')}} </el-row>
          <el-row style="padding-top: 5px;"><el-input  v-model="node.name"  /> </el-row>
          <el-row style="padding-top: 5px;">{{$t('projectlist.seetabs.editlabel.Chinesename')}} </el-row>
          <el-row style="padding-top: 5px;"><el-input v-model="node.label" @change="handleChangeName" /> </el-row>
          <el-row style="padding-top: 5px;"> <el-button size="mini" icon="el-icon-edit" @click="opDailog"></el-button>  
          
            <el-button size="mini"  @click="opDailog2" v-if="showD">{{$t('projectlist.btn.Taskbuilding')}}</el-button>
          </el-row>
          <el-row style="padding-top: 5px;">{{$t('projectlist.btn.DocumentContent')}} </el-row>
          <el-row style="padding-top: 5px;"><el-input ref="ipt" v-model="node.center" type="textarea" :rows="3"/> </el-row>

          <el-dialog
  :title="$t('projectlist.listedit.edit')"
  :visible.sync="dialogVisible"
  width="40%"
  :before-close="handleClose"
  append-to-body>

  <div>
    <el-row>{{$t('projectlist.btn.DocumentContent')}} </el-row>
          <el-row>  &nbsp; </el-row>
          <el-row><el-input v-model="node.center" type="textarea" :rows="8"/> </el-row>
  </div>
  
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">{{$t('cancelText')}}</el-button>
    <el-button type="primary" @click="dialogVisible = false">{{$t('submitText')}}</el-button>
  </span>
</el-dialog>
        </div>

        

        
      </div>
      <div v-if="status==='canvas-selected'" class="pannel" id="canvas_detailpannel">
        <div class="pannel-title">{{$t('projectlist.btn.canvas')}}</div>
        <div class="block-container">
          <el-checkbox v-model="showGrid" @change="changeGridState">{{$t('projectlist.btn.Gridalignment')}}</el-checkbox>
        </div>
      </div>
      
    </div>

   

<el-dialog
  :title="$t('projectlist.btn.Taskbuilding')"
  :visible.sync="dialogVisible2"
  width="70%"
  :before-close="handleClose"
  @close="closeD"
  append-to-body>

  <div v-if="dialogVisible2">
    <!-- <iframe id="iframe1" @load="loadFrame" style="border: 0;"  :src =src1 width="100%" height="800px"></iframe> -->
    <offLine></offLine>
  </div>
  
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2 = false">{{$t('cancelText')}}</el-button>
    <el-button type="primary" @click="dialogVisible2 = false">{{$t('submitText')}}</el-button>
  </span>
</el-dialog>

    
  </div>
</template>

<script>
import eventBus from "@/util/eventBus";
import offLine from "../../../../dataAcquisition/offline.vue"

import Grid from "@antv/g6/build/grid";
export default {
  components:{
    offLine
  },
  data() {
    return {
      src1:'',
      dialogVisible: false,
      dialogVisible2: false,
      status: "canvas-selected",
      showGrid: false,
      page: {},
      graph: {},
      item: {},
      node: {center:""},
      grid: null,
      showD:false, //判断是否是json文件
    };
  },
  created() {
    this.init();
    this.bindEvent();
    this.src1 = this.src1+"/platform#/dataAcquisition/offline?other=123&isHead=1"
    var that = this
    window.addEventListener('message', function (e) {
      var res = e.data;
      if(res.shou){   

        that.node.center = res.msg
        that.dialogVisible2 = false
        
      }
      })
  },
  mounted(){
    
  },
  methods: {
    closeD(){
      sessionStorage.removeItem("isDialog")
    },
    opDailog(){
      this.dialogVisible = true
    },

    opDailog2(){
      sessionStorage.setItem("isDialog","1")
      this.dialogVisible2 = true
    
      
    },
    loadFrame(){
      var a = document.getElementById("iframe1")
    },
    init() {},
    bindEvent() {
      let self = this;
     
      eventBus.$on("afterAddPage", page => {
        self.page = page;
        self.graph = self.page.graph;
        
        eventBus.$on("nodeselectchange", item => {
          if (item.select === true && item.target.getType() === "node") {
            self.status = "node-selected";
            
            self.item = item.target;
            self.node = item.target.getModel();
            if(self.node.suffix == "json"){
              this.showD = true
            }else{
              this.showD = false
            }
          } else {
            self.status = "canvas-selected";
            self.item = null;
            console.log("选中了2",self.node);
            self.node = null;
          }
        });
      });
    },
    handleChangeName(e) {
      const model = {
        label: e
      };

      this.graph.update(this.item, model);
    },
    changeGridState(value) {
      if (value) {
        this.grid = new Grid();
        this.graph.addPlugin(this.grid);
      } else {
        this.graph.removePlugin(this.grid);
      }
    }
  }
};
</script>

<style scoped>
.detailpannel {
  height: 100%;
  position: absolute;
  right: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 200px;
  border-left: 1px solid #e6e9ed;
}
.detailpannel .block-container {
  padding: 16px 8px;
}
.block-container .el-col {
  height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.pannel-title {
  height: 32px;
  border-top: 1px solid #dce3e8;
  border-bottom: 1px solid #dce3e8;
  background: #ebeef2;
  color: #000;
  line-height: 28px;
  padding-left: 12px;
}
::v-deep .el-input__inner{
  height: 30px;
}
</style>

